import * as React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styles from './CommisionContnent.module.css'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import image1 from '@assets/images/form/kommision_1.jpg'
import image2 from '@assets/images/form/kommision_2.jpg'
import image3 from '@assets/images/form/kommision_3.jpg'


export const CommisionContent: React.FC = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <section className={styles.commisionContent}>
        <div className={styles.commisionContent__container}>
          <div className={styles.commisionContent__section}>
            <div className={styles.commisionContent__right}>
              <h2 className={styles.commisionContent__title}>{t('pages.commission.content.rightPriceTitle')}</h2>
              <p>{t('pages.commission.content.rightPriceText')}</p>
            </div>
            <div className={styles.commisionContent__left}>
              <img src={image1} alt="Commision Image" loading="lazy" className={styles.commisionContent__image} />
            </div>
          </div>
          <div className={styles.commisionContent__section}>
            <div className={styles.commisionContent__left}>
              <img src={image2} alt="Commision Image" loading="lazy" className={styles.commisionContent__image} />
            </div>
            <div className={styles.commisionContent__right}>
              <h2 className={styles.commisionContent__title}>{t('pages.commission.content.exchangeTitle')}</h2>
              <p>{t('pages.commission.content.exchangeText')}</p>
            </div>
          </div>
          <div className={styles.commisionContent__section}>
            <div className={styles.commisionContent__right}>
              <h2
                className={styles.commisionContent__title}>{t('pages.commission.content.professionalInspectionTitle')}</h2>
              <p>{t('pages.commission.content.professionalInspectionText')}</p>
            </div>
            <div className={styles.commisionContent__left}>
              <img src={image3} alt="Commision Image" loading="lazy" className={styles.commisionContent__image} />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.accordionContainer}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              className={styles.accordionContainer__title}>{t('pages.commission.content.leasingAdviceTitle')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{t('pages.commission.content.leasingAdviceText')}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              className={styles.accordionContainer__title}>{t('pages.commission.content.financingTitle')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{t('pages.commission.content.financingText')}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              className={styles.accordionContainer__title}>{t('pages.commission.content.adsInBalticsTitle')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{t('pages.commission.content.adsInBalticsText')}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              className={styles.accordionContainer__title}>{t('pages.commission.content.secureStorageTitle')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div>{t('pages.commission.content.secureStorageText')}</div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div
              className={styles.accordionContainer__title}>{t('pages.commission.content.commissionSaleTermsTitle')}</div>
          </AccordionSummary>
          <AccordionDetails>
            <Trans i18nKey="pages.commission.content.commissionSaleTermsText" components={{ 1: <br /> }} />
          </AccordionDetails>
        </Accordion>
      </section>
    </>
  )
}
