import * as React from 'react'
import { TopicEnum } from '@api/endpoints/forms'
import { VehicleMinimalInfo } from '@stores/forms'
import { FormConfirmationMessagePanel } from '@controls/panels'
import { ContactForm } from '@forms/ContactForm'
import { DealerContacts } from '@layout/DealerContacts'
import { FlexContainer } from '@layout/FlexContainer'

export type VehicleFormSuccessProps = Readonly<{
  topic: TopicEnum
  data: VehicleMinimalInfo
  onReset: () => void
  showContacts?: boolean
}>

export const VehicleFormSuccess: React.FC<VehicleFormSuccessProps> = ({ topic, onReset, showContacts = true }) => {
  return (
    <FlexContainer vertical>
      {/*
      <div className={formStyles.carDetails}>
        <div>{data.carNumber}, {data.mark}, {data.model}</div>
      </div>
      */}
      <FormConfirmationMessagePanel onReset={onReset} />
      {showContacts && (
        <>
          <ContactForm topic={topic} />
          <DealerContacts />
        </>
      )}
    </FlexContainer>
  )
}
